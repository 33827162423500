import React, { Component, } from 'react';
import {
  arrayOf, bool, object, string, func,
} from 'prop-types';
import { Query, withApollo, } from 'react-apollo';
import styled from 'styled-components';

import { QUERY_PLATFORM_BLOCKS_TRANSLATIONS, } from '../gql/translations';
import { withTranslations, } from '../../../logic/translations/withTranslations';
import Card from '../../../atoms/Card/Card';
import Breadcrumb from '../../../atoms/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../atoms/Breadcrumb/BreadcrumbItem';
import PageFullScreen from '../../../atoms/PageView/PageFullScreen';
import ReservationOverviewTable from '../components/PlatformBlocks/ReservationOverviewTable';
import BlockingCalendar from '../components/PlatformBlocks/BlockingCalendar';
import {
  addDateObjToBlockedDays,
  parseFetchedPlatformBlockDefinitions,
  filterSelectedPlatformBlocks,
  parseFetchedPlatformBlocks,
} from '../components/PlatformBlocks/utils';
import { QUERY_BLOCKS_IN_MONTH, QUERY_CONFIG_FOR_PLATFORM_BLOCKS, } from '../gql/queries';
import ModalDelete from '../../../components/modal/ModalDelete';
import {
  MUTATION_CREATE_PLATFORM_BLOCK,
  MUTATION_DELETE_PLATFORM_BLOCK_DEFINITION,
  MUTATION_DELETE_RESERVATION,
} from '../gql/mutations';
import Loader from '../../../atoms/Loader/Loader';
import PartError from '../../../components/Parts/PartError';
import TransportationDetail from '../../Transportation/components/TransportationDetail/TransportationDetail';
import { withUser, } from '../../../logic/auth/withUser';
import { pipe, } from '../../../logic/utils';
import BlockingTable from '../components/PlatformBlocks/BlockingTable';
import BlockingForm from '../components/PlatformBlocks/BlockingForm';
import {
  BlockedDay, Platform, PlatformBlock, PlatformBlockDefinition,
} from '../components/PlatformBlocks/propTypes';
import ModalDeletePlatformBlock from '../components/PlatformBlocks/ModalDeletePlatformBlock';
import { withNotifications, } from '../../../logic/notifications/withNotifications';


const StyledPlatformBlocksPage = styled.div`
  padding-bottom: 1rem;

  .header-with-loader {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    > div {
      margin: -4px 0 0 1rem;
    }
  }
  
  h3 {
    margin-top: 2rem;
  }
`;

class PlatformBlocksPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDate: new Date(),
      selectedPlatformBlocks: [],

      detailTransportationModal: {
        isOpen: false,
        id: null,
      },
      removeReservationModal: {
        isOpen: false,
        id: null,
        refetch: 0,
      },
      removePlatformBlockModal: {
        isOpen: false,
        blockId: null,
        blockDefinitionId: null,
        groupId: null,
      },
      removePlatformBlockDefinitionModal: {
        isOpen: false,
        id: null,
      },
    };
  }

  /**
   * Blocking days updates
   */

  componentDidUpdate(prevProps) {
    const { platformBlocks, } = this.props;

    if (prevProps.platformBlocks !== platformBlocks) {
      this.updatePlatformBlocks(platformBlocks);
    }
  }

  updatePlatformBlocks = (platformBlocks) => {
    const { selectedDate, } = this.state;

    this.setState({
      selectedPlatformBlocks: filterSelectedPlatformBlocks(platformBlocks, selectedDate),
    });
  }

  handleCalendarDateClick = (date) => {
    const { platformBlocks, } = this.props;

    date.setHours(12);

    this.setState({
      selectedDate: date,
      selectedPlatformBlocks: filterSelectedPlatformBlocks(platformBlocks, date),
    });
  }

  handleTransportationDeleted = () => {
    this.setState((prevState) => ({
      removeReservationModal: {
        id: prevState.removeReservationModal.id,
        isOpen: prevState.removeReservationModal.isOpen,
        refetch: prevState.removeReservationModal.refetch + 1,
      },
    }));
  }

  handleDisplayedDateChange = (displayedDate) => {
    const { onDisplayedDateChange, } = this.props;

    this.setState({
      selectedDate: displayedDate,
    });

    onDisplayedDateChange(displayedDate);
  }

  /**
   * Modals toggling
   */

  toggleDetailTransportationModal = (transportation) => {
    this.setState((prevState) => ({
      detailTransportationModal: {
        isOpen: !prevState.detailTransportationModal.isOpen,
        id: transportation ? transportation.id : null,
      },
    }));
  }

  toggleModalRemoveNotification = (id = null) => {
    this.setState((prevState) => ({
      removeReservationModal: {
        id,
        isOpen: !prevState.removeReservationModal.isOpen,
        refetch: prevState.removeReservationModal.refetch,
      },
    }));
  }

  toggleModalRemovePlatformBlock = (blockId = null, blockDefinitionId = null, groupId = null) => {
    this.setState((prevState) => ({
      removePlatformBlockModal: {
        isOpen: !prevState.removePlatformBlockModal.isOpen,
        blockId,
        blockDefinitionId,
        groupId,
      },
    }));
  }

  toggleModalRemovePlatformBlockDefinition = (id = null) => {
    this.setState((prevState) => ({
      removePlatformBlockDefinitionModal: {
        isOpen: !prevState.removePlatformBlockDefinitionModal.isOpen,
        id,
      },
    }));
  }

  handleCreatePlatformBlock = async (values) => {
    const { client, addNotification, } = this.props;

    const result = await client.mutate({
      mutation: MUTATION_CREATE_PLATFORM_BLOCK,
      variables: values,
    });

    if (!result.data.createPlatformBlock.result) {
      if (result.data.createPlatformBlock.error === 'PLATFORM_BLOCK_CONFLICT') {
        addNotification({ status: 'error', title: 'Nelze vytvořit. Pro některou pozici už v tomto čase blokace existuje', });
      } else {
        addNotification({ status: 'error', title: 'Neznámá chyba', });
      }
    } else {
      addNotification({ status: 'success', title: 'V pořádku vytvořeno', });
    }
  }

  /**
   * Render
   */

  render() {
    const {
      selectedDate, selectedPlatformBlocks,
      detailTransportationModal, removeReservationModal,
      removePlatformBlockModal, removePlatformBlockDefinitionModal,
    } = this.state;
    const {
      actualDate, displayedDate, platforms,
      translations, translationsCommon, languageId, configLoading, platformBlocksLoading, error,
      refetchPlatformBlocks, blockedDays, resources, subscribeToMore,
    } = this.props;

    return (
      <PageFullScreen>
        {detailTransportationModal.isOpen && (
          <TransportationDetail
            transportationId={detailTransportationModal.id}
            onToggle={this.toggleDetailTransportationModal}
          />
        )}

        {removeReservationModal.isOpen && (
          <ModalDelete
            // data
            title={translations.rs.platformBlocks.deleteReservationModalTitle}
            text={translations.rs.platformBlocks.deleteReservationModalText}
            mutation={MUTATION_DELETE_RESERVATION}
            variables={{ id: removeReservationModal.id, }}
            // methods
            onToggle={this.toggleModalRemoveNotification}
            onCompleted={() => this.handleTransportationDeleted(removeReservationModal.id)}
          />
        )}

        {removePlatformBlockModal.isOpen && (
          <ModalDeletePlatformBlock
            // data
            blockId={removePlatformBlockModal.blockId}
            blockDefinitionId={removePlatformBlockModal.blockDefinitionId}
            groupId={removePlatformBlockModal.groupId}
            // methods
            onToggle={this.toggleModalRemovePlatformBlock}
            onCompleted={refetchPlatformBlocks}
          />
        )}

        {removePlatformBlockDefinitionModal.isOpen && (
          <ModalDelete
            // data
            title="Smazat opakovanou blokaci"
            text="Opravdu si přejete smazat danou opakovanou blokaci?"
            mutation={MUTATION_DELETE_PLATFORM_BLOCK_DEFINITION}
            variables={{ id: removePlatformBlockDefinitionModal.id, }}
            // methods
            onToggle={this.toggleModalRemovePlatformBlockDefinition}
            onCompleted={refetchPlatformBlocks}
          />
        )}

        <Breadcrumb>
          <BreadcrumbItem to="/">{translations.common.home}</BreadcrumbItem>
          <BreadcrumbItem>
            {translations.common.rsPlatformBlocks}
          </BreadcrumbItem>
        </Breadcrumb>

        {configLoading && (
          <Card>
            <Loader />
          </Card>
        )}

        {!configLoading && error && (
          <Card>
            <PartError />
          </Card>
        )}

        {!configLoading && !error && (
          <StyledPlatformBlocksPage>
            <Card>
              <h2 className="header-with-loader">
                <span>
                  {translations.rs.platformBlocks.daySelect}
                </span>
                {platformBlocksLoading && (
                  <Loader />
                )}
              </h2>

              <BlockingCalendar
                actualDate={actualDate}
                displayedDate={displayedDate}
                selectedDate={selectedDate}
                blockedDays={blockedDays}
                onDateClick={this.handleCalendarDateClick}
                onDisplayedDateChange={this.handleDisplayedDateChange}
                translations={translations}
                translationsCalendar={translationsCommon.calendar}
              />
            </Card>

            <Card>
              <h2 className="header-with-loader">
                <span>
                  Blokace a rezervace na datum
                  {' '}
                  {selectedDate.toLocaleDateString()}
                </span>
                {platformBlocksLoading && (
                  <Loader />
                )}
              </h2>

              <h3>
                Vytvořit novou jednorázovou blokaci na daný den
              </h3>

              <BlockingForm
                platforms={platforms}
                timeStepInMinutes={10}
                onSubmit={this.handleCreatePlatformBlock}
                translations={translations}
                definitionMode={false}
              />

              <h3>Vytvořené blokace na daný den</h3>

              <BlockingTable
                platformBlocks={selectedPlatformBlocks}
                onDeletePlatformBlock={this.toggleModalRemovePlatformBlock}
                subscribeToMore={subscribeToMore}
                refetchPlatformBlocks={refetchPlatformBlocks}
              />

              <h3>
                Vytvořené rezervace na daný den
              </h3>

              <ReservationOverviewTable
                // data
                selectedDate={selectedDate}
                translations={translations}
                languageId={languageId}
                resources={resources}
                refetch={removeReservationModal.refetch}
                // methods
                onDetail={this.toggleDetailTransportationModal}
                toggleRemove={this.toggleModalRemoveNotification}
              />
            </Card>

          </StyledPlatformBlocksPage>
        )}
      </PageFullScreen>
    );
  }
}

PlatformBlocksPage.propTypes = {
  actualDate: object.isRequired,
  displayedDate: object.isRequired,
  onDisplayedDateChange: func.isRequired,

  platformBlocks: arrayOf(PlatformBlock.isRequired).isRequired,
  blockedDays: arrayOf(BlockedDay.isRequired).isRequired,
  refetchPlatformBlocks: func.isRequired,

  platforms: arrayOf(Platform.isRequired).isRequired,

  configLoading: bool.isRequired,
  platformBlocksLoading: bool.isRequired,
  error: object,
  subscribeToMore: func.isRequired,

  translations: object.isRequired,
  translationsCommon: object.isRequired,
  languageId: string.isRequired,
  resources: object.isRequired,
  client: object.isRequired,
  addNotification: func.isRequired,
};

PlatformBlocksPage.defaultProps = {
  error: undefined,
};


class PlatformBlocksPageWrapper extends Component {
  constructor(props) {
    super(props);

    const actualDate = new Date();

    this.state = {
      actualDate,
      displayedDate: actualDate,
      displayedYear: actualDate.getFullYear(),
      displayedMonth: actualDate.getMonth(),
    };
  }

  handleDisplayedDateChange = (date) => {
    const displayedDate = new Date(date);
    displayedDate.setHours(12);

    this.setState({
      displayedDate,
      displayedYear: date.getFullYear(),
      displayedMonth: date.getMonth(),
    });
  }

  render() {
    const {
      translations, translationsCommon, languageId, resources, client, addNotification,
    } = this.props;
    const {
      actualDate, displayedDate, displayedYear, displayedMonth,
    } = this.state;

    return (
      <Query
        query={QUERY_CONFIG_FOR_PLATFORM_BLOCKS}
        fetchPolicy="no-cache"
      >
        {(configResult) => {
          const platforms = (
            configResult.loading
            || !configResult.data
            || !configResult.data.fetchConfigForPlatformBlocks
          )
            ? []
            : configResult.data.fetchConfigForPlatformBlocks;

          return (
            <Query
              query={QUERY_BLOCKS_IN_MONTH}
              fetchPolicy="no-cache"
              variables={{ year: displayedYear, month: displayedMonth, }}
            >
              {(blocksResult) => {
                const platformBlocks = (
                  blocksResult.data
                  && blocksResult.data.fetchBlocksInMonth
                  && blocksResult.data.fetchBlocksInMonth.platformBlocks
                )
                  ? parseFetchedPlatformBlocks(blocksResult.data.fetchBlocksInMonth.platformBlocks)
                  : [];

                const blockedDays = (
                  blocksResult.data
                  && blocksResult.data.fetchBlocksInMonth
                  && blocksResult.data.fetchBlocksInMonth.blockedDays
                )
                  ? addDateObjToBlockedDays(blocksResult.data.fetchBlocksInMonth.blockedDays)
                  : [];

                const platformBlockDefinitions = (
                  blocksResult.data
                  && blocksResult.data.fetchBlockDefinitions
                )
                  ? parseFetchedPlatformBlockDefinitions(
                    blocksResult.data.fetchBlockDefinitions,
                    platforms,
                  ) : [];

                return (
                  <PlatformBlocksPage
                    actualDate={actualDate}
                    displayedDate={displayedDate}
                    onDisplayedDateChange={this.handleDisplayedDateChange}

                    platformBlocks={platformBlocks}
                    blockedDays={blockedDays}
                    platformBlockDefinitions={platformBlockDefinitions}
                    refetchPlatformBlocks={() => blocksResult.refetch({
                      year: displayedYear,
                      month: displayedMonth,
                    })}

                    platforms={platforms}

                    configLoading={configResult.loading}
                    platformBlocksLoading={blocksResult.loading}
                    error={configResult.error || blocksResult.error}
                    subscribeToMore={blocksResult.subscribeToMore}

                    translations={translations}
                    translationsCommon={translationsCommon}
                    languageId={languageId}
                    resources={resources}
                    client={client}
                    addNotification={addNotification}
                  />
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

PlatformBlocksPageWrapper.propTypes = {
  translations: object.isRequired,
  translationsCommon: object.isRequired,
  resources: object.isRequired,
  languageId: string.isRequired,
  client: object.isRequired,
};

export default pipe(
  withTranslations(QUERY_PLATFORM_BLOCKS_TRANSLATIONS),
  withUser,
  withApollo,
  withNotifications,
)(PlatformBlocksPageWrapper);
